.App {
  text-align: center;
  font-family: 'Chum';
}

*.unselectable {
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

body{
  background-color: #eea687;
  cursor: url(pencil.png), auto !important;
}

.pencil{
  cursor: url(pencil.png), auto;
}

h1{
  font-size: 64px;
  color: #284532;
  margin: 0;
}

.namecard{
  display: inline-block;
  background-color: white;
  padding: 20px;
  border: 2px solid black;
  margin: 40px auto;
}

@font-face {
  font-family: Chum;
  src: url('./Krabby_Patty.ttf');
}

.full-screen{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .namecard{
    width: 80%;
  }

  h1{
    font-size: 40px;
  }
}
